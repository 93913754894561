$primaryColor: #7555f8;
$textColor: #6f7378;

@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
  border-color: #babfc7;
  border-color: none;
  font-family: "Poppins", sans-serif !important;
  
}
  .ag-header {
    background-color: #fff !important;
    color: #3e4676;
    font-size: 12px;
    border-bottom: 0px;

  }
  .ag-header-cell-text {
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
    font-size: 0.75rem;
    line-height: 1rem;
}
.ag-ltr .ag-cell {
  font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
    font-size: 0.75rem;
}
  .ag-row {
    color: #3e4676;
    font-size: 0.85rem;
    font-weight: 400;
    vertical-align: middle;


  }

  .ag-cell {
    vertical-align: middle !important;
  }

  .ag-row-odd {
    background-color: #F8F9FA !important;
  }
  @keyframes custom-bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
    60% {
      transform: translateY(-11px);
    }
  }
  
  .custom-bounce-1 {
    animation: custom-bounce 0.8s infinite;
  }
  
  .custom-bounce-2 {
    animation: custom-bounce 0.8s infinite -0.2s;
  }
  
  .custom-bounce-3 {
    animation: custom-bounce 0.8s infinite -0.4s;
  }
  
  .nortification{
    display:block;
    font-size:14px;
    width:180px;
    padding:5px 0;
    position:absolute;
    top:0;
    left:50%;
    margin-left:-90px;
    box-sizing:border-box;
    border-radius:15px;
    background-color:#fff;
    color:#3a9ab9;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 3px 0 rgba(0,0,0,0.2);
  }
  
  .leftNav li a.active {
    color: $primaryColor
  }